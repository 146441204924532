@import "../../scss/variables";

.menu-bar-component {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  min-height: 100vh;
  height: 100%;
  z-index: 4;
  background-color: $white;
  position: relative;
  padding-top: 70px;
  max-width: 250px !important;
  transition: all 0.3s ease;

  .logodiv{
    img{
      width: 100%;
      height: auto;
      margin-top: -100px;
    }
  }

  .user {
    text-align: center;
    position: relative;

    .img {
      border-radius: 100%;
      overflow: hidden;
      background-color: $white;
      padding: 30px 15px 0;
      width: 45px;
      height: 45px;
      margin: 0 auto 15px;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 0 5px 1px rgba(0,0,0,0.3);
      position: absolute;
      display: block;
      right: -22.5px;
      top: -15px;
      svg{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 80%;
        height: 80%;
        background-color: $black;
        fill: $white;
        border-radius: 100%;
      }
    }

    h3 {
      font-size: 14px;
      font-weight: 300;
      width: 98%;
      padding-bottom: 0;
      text-transform: capitalize;
      color: silver;
      text-align: left;
    }

    h4 {
      margin: 5px auto 15px;
      padding-bottom: 15px;
      border-bottom: 0.8px solid rgba(silver, 0.4);
      font-size: 20px;
      text-transform: uppercase;
      text-align: left;
      font-weight: 600;
    }

  }

  .main-menu {
    margin: 0 -15px !important;
    max-height: calc(100vh - 190px);
    overflow: auto;

    .MuiListSubheader-gutters {
      background-color: silver;
      color: $white;
      font-size: 17px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .MuiCollapse-container {
      padding-left: 42px;
    }

    .MuiList-subheader {
      padding-bottom: 0;
    }
  }

  .MuiListItemIcon-root {
    min-width: 42px;
  }

  .content-list {
    overflow: auto;
    height: auto;
  }

  .icons-list{
    width: 28px;
    img{
      width: 100%;
      height: auto;
    }
  }

  .MuiListItem-button.active,
  .MuiListItem-button:hover{
    background: rgb(173,225,16);
    background: linear-gradient(90deg, rgba(173,225,16,1) 0%, rgba(118,180,15,1) 100%);
    border-radius: 20px 0 0 20px;
    span{
      font-weight: 600;
    }
  }
}
