.main-layout {
  height: 100%;
  background-image: url("../../resources/images/backdash.jpg");
  background-size: auto 100%;
  background-position: right top;
  background-repeat: no-repeat;

  .main-layout-content {
    height: 100vh;
    overflow: auto;
  }
}
