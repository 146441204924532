@import "reset";
@import "variables";
@import "../fonts/stylesheet.css";

html, a, i, li, p, h1, h2, h3, h4, h5, h6, input, textarea, button, label, span, b {
  font-family: 'Bahnschrift', serif;
  box-sizing: border-box;
}

div {
  box-sizing: border-box;
}

#translaval-root,
html, body {
  background: #f7f7f7;
  min-height: 100vh;
  overflow: hidden;
}

.transalaval-app{
  min-height: 100vh;
}

//hide
.hide {
  display: none !important;
}

//Position elements inline/block
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

//Paddings
.no-padding {
  padding: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

//Margins
.no-margin {
  margin: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.max-container {
  max-width: $sm-container-width !important;
  margin: 0 auto !important;
  display: block !important;
}

b, strong {
  font-weight: 600;
}

html body {
  .fc-unthemed td.fc-today{
    background: transparent;
  }
  .fc-button{
    &.fc-button-active, &{
      background-color: $grennBtn !important;
      border-color: $grennBtn !important;
    }
  }
  .fc-content-skeleton22{
    position: absolute;
    left: 0;
    top: -34px;
    height: 100%;
    width: 100%;
  }
  .fc-event, .fc-event-dot{
    background-color: $grennBtn;
    border: 1px solid $grennBtn;
  }
}
.swal2-styled.swal2-confirm{
  background-color: $lime !important;
  border-left-color: $lime !important;
  border-right-color: $lime !important;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $black;
  border-radius: 25px;
  padding: 5px 45px;
}

.MuiFormLabel-root{
  &.Mui-focused{
    color: $black !important;
  }
}

.MuiAutocomplete-popper > div{
}

.w-49{
  max-width: 49% !important;
  min-width: 49% !important;
  width: 49% !important;
}

.w-50{
  max-width: 50% !important;
  min-width: 50% !important;
  width: 50% !important;
}

.w-100{
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
}

.d-i{
  display: inline-block;
}

body .MuiTableCell-root{
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);
  &:last-child{
    border: 0;
  }
}

.formdata .MuiFormControl-marginNormal{
  margin-top: 0;
}

.formdata{
  border-radius: 26px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  background-color: $white;
  min-height: 300px;
  width: 95%;
  margin: 40px auto;
  padding: 20px 25px;
  h2{
    font-size: 25px;
    letter-spacing: 3px;
    white-space: nowrap;
  }
  .MuiButtonBase-root{
    h2{
      font-size: 16px;
    }
  }
  .MuiCheckbox-root{
    margin-bottom: 15px;
  }
  .MuiFormControlLabel-root{
    .MuiTypography-root{
      vertical-align: top;
      margin-top: -15px;
    }
  }
  .MuiTab-root{
    max-width: inherit;
    white-space: nowrap;
    width: auto;
    background-color: silver;
    border-radius: 15px 15px 0 0;
    color: $black;
    opacity: 0.4;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: inherit;
    position: relative;
    top: 6px;
    &.Mui-selected{
      color: $black;
      opacity: 1;
    }
  }
  .list-items{
    li{
      border-bottom: 1px solid silver;
      padding-top: 15px;
      padding-bottom: 15px;
      p{
        width: 100%;
        b{
          font-size: 15px;
          font-weight: 600;
          color: $dove;
          font-family: sans-serif;
        }
        span{
          font-size: 16px;
        }
        span, b{
          width: 49%;
          display: inline-block;
        }
      }
      &:first-child{
        padding-top: 0;
      }
    }
  }
  .MuiTabs-indicator{
    height: 4px;
  }
  .MuiAppBar-colorDefault{
    background-color: transparent;
    box-shadow: none;
    margin-bottom: -15px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
    position: relative;
    z-index: 99;
  }
  .MuiTabs-scrollButtons{
    svg{
      fill: $grennBtn;
      font-size: 2.5rem;
      position: relative;
      top: 5px;
    }
    &:first-child svg{
      right: -6px;
    }
    &:last-child svg{
      left: -6px;
    }
  }
  .top{
    text-align: right;
    p{
      font-size: 14px;
      color: gray;
      display: inline-block;
    }
    span{
      color: $black;
      margin-left: 10px;
      display: inline-block;
      font-size: 18px;
      vertical-align: middle;
      border: 1px solid $lime-hover;
      padding: 4px 25px;
      box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
      border-radius: 18px;
    }
  }
  .btns .wrap-btns{
    text-align: center;
    padding-top: 15px;
    .btn{
      margin: 0 15px 0 0 !important;
      display: inline-block;
    }
  }
  .wrap{
    background-color: scale-color(silver, $lightness: 75%);
    min-height: 300px;
    border-radius: 15px;
    padding: 35px 20px;
    margin: 15px 0 5px !important;
    .MuiTextField-root,
    .MuiAutocomplete-root{
      width: 49% !important;
      display: inline-block;
      margin-right: 2%;
      margin-bottom: 30px;
      max-height: 37px;
      &.milti-field{
        max-height: inherit;
      }
      &:nth-child(even){
        margin-right: 0;
      }
      border-radius: 12px;
      background-color: scale-color(silver, $lightness: 40%);
      &.date-field{
        label{
          padding-left: 20px;
          &.MuiInputLabel-shrink,
          &.Mui-focused{
            margin-top: -18px;
            font-weight: 600;
          }
        }
      }
      .MuiInput-root{
        width: 100% !important;
        margin-top: 0;
        &:before{
          border-radius: 12px;
          border-color: transparent !important;
        }
      }
      .MuiFormControl-root{
        width: 100% !important;
        display: block;
        max-width: 100%;
      }
      label{
        margin-top: -9px;
        &.MuiInputLabel-shrink,
        &.Mui-focused{
          margin-top: -12px;
          font-weight: 600;
        }
      }
      .MuiInputBase-root{
        width: 100%;
      }
      fieldset{
        border-radius: 12px;
        border-color: scale-color(silver, $lightness: 40%) !important;
      }
      &.field-observa{
        min-height: 150px;
        .MuiInputBase-root{
          width: 100%;
          min-height: 150px;
          padding-top: 0;
          padding-bottom: 0;
        }
        textarea{
          width: 100%;
          height: 120px !important;
        }
      }
    }
    .MuiAutocomplete-root {
      input{
        padding: 0 !important;
      }
    }
    .MuiTextField-root{
      input{
        padding: 8px 15px 9px;
      }
    }
    .group{
      .MuiTextField-root,
      .MuiAutocomplete-root {
        width: 100% !important;
      }
      .col:last-child{
        padding-left: 0 !important;
      }
    }
    h3{
      width: 100%;
      border-bottom: 1px solid silver;
      margin: 0 0 30px;
    }
    h4{
      color: $verdun;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 30px;
    }
    .d-i{
      vertical-align: top;
    }
  }
  &.content{
    .wrap{
      border-radius: 0 0 15px 15px;
    }
  }
  .max{
    margin-right: 2%;
  }
  .btn{
    text-transform: capitalize !important;
    &.add-contact{
      font-size: 14px !important;
      &.more{
        margin-bottom: 15px;
      }
    }
    &.btn-secondary{
      float: right;
      &.remove{
        margin-top: -15px;
        margin-bottom: 5px;
      }
    }
  }
  .btns{
    .btn{
      margin-top: 25px;
      &.btn-primary{
        min-width: 170px;
        margin-left: 42.5%;
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
  .files-component{
    width: 49%;
    display: inline-block;
    margin-right: 2%;
    &:nth-child(2){
      margin-right: 0;
    }
    .box{
      h5, h6{
        display: inline-block;
        font-size: 14px;
      }
    }
    label{
      font-size: 16px;
    }
    .btn{
      text-align: center;
      width: 100%;
      display: block;
      height: 34px;
      font-size: 16px;
      vertical-align: middle;
      letter-spacing: 2px;
      font-weight: 600;
      margin-top: 5px;
      svg{
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .active-row{
    position: relative;
    z-index: 2;
    td{
      position: relative;
      z-index: 2;
      font-weight: 600;
    }
  }
  .colpwrap{
    padding: 0 25px;
    text-align: center;
    position: relative;
    >div{
      background-color: $white;
      box-shadow: 0 0 15px 0 rgba(0,0,0,0.25);
      padding: 15px 0;
      border-radius: 0 0 8px 8px;
    }
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      height: 42px;
      left: 0;
      top: -43px;
      background: #76b40f;
      background: linear-gradient(90deg, #76b40f 0%, #ade110 100%);
      border-radius: 20px;
      z-index: 1;
      display: block;
    }
    .btn{
      margin-right: 15px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

.swal2-container{
  z-index: 9999;
}

.popup-component {
  background-color: $alabaster;
  max-width: 700px;
  max-height: 630px;
  height: 100vh;
  overflow: auto;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto !important;
  transition: all 0.3s ease 0.3s;
  border-radius: 15px;
  @media (max-width: 767px) {
    width: 90% !important;
    max-height: 90vh !important;
    height: auto !important;
    overflow: auto;
  }

  &.tap-register {
    height: 650px;
  }

  .MuiTabs-indicator {
    height: 3px;
  }

  .MuiTabs-scroller {
    max-width: 60%;
    @media (max-width: 767px) {
      max-width: 78%;
    }
  }

  .MuiTab-root {
    min-width: 150px;
    @media (max-width: 767px) {
      min-width: 100px;
    }
  }

  .MuiAppBar-colorDefault {
    box-shadow: none;
    border-bottom: 1px solid silver;
    background-color: $alabaster;
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    width: 49px;
    height: 49px;
    display: block;
    z-index: 1;
    cursor: pointer;
    background-image: url("../resources/images/close.svg");
    background-size: 16px auto;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.3s ease;
    border-radius: 10px;

    &:hover {
      background-image: url("../resources/images/closeWhite.svg");
      background-color: $grennBtn;
    }
  }

  .MuiFormHelperText-root {
    margin-top: -5px;
    margin-bottom: 0;
  }
  .formdata{
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    .wrap{
      margin-left: auto !important;
      margin-right: auto !important;
      width: 95%;
    }
  }
}
.fc-timeGridWeek-view{
  .fc-time-grid-event .fc-time, .fc-time-grid-event .fc-title{
    font-size: 12px;
  }
}

.wrapfield{
  .iconclose{
    position: absolute;
  }
}
