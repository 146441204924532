@import "../../scss/variables";

//Generic components
.btn {
  width: auto;
  padding: 16px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  margin: 10px 0;
  border: 0;
  transition: background-color 0.3s ease;
  cursor: pointer;
  font-family: sans-serif;

  &.btn-block{
    display: block;
    width: 100%;
  }

  &.btn-danger,
  &.btn-primary {
    background-color: $grennBtn;
    font-size: 15.5px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);

    &.active {
      background-color: scale-color($grennBtn, $lightness: -20%);
    }

    @media (min-width: 769px) {
      &:hover {
        background-color: scale-color($grennBtn, $lightness: -20%);
      }
    }
  }

  &.btn-secondary{
    &,
    &:hover{
      background-color: transparent;
      color: $error;
      box-shadow: inherit;
    }
    &:hover span{
      font-weight: 600;
      color: scale-color($error, $lightness: -20%);;
    }
  }
  &.btn-danger{
    background-color: $error;
    &:hover{
      background-color: scale-color($error, $lightness: -20%);;
    }
    &:hover span{
      font-weight: 600;
    }
  }

  &.btn-default {
    background-color: transparent;
    border-radius: 3px;
    border: solid 1px $lime;
    color: $lime;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    &.active {
      background-color: $lime;
      color: $white;
    }

    @media (min-width: 769px) {
      &:hover {
        background-color: $lime;
        color: $white;
      }
    }
  }

  &.btn-link {
    background-color: transparent;
    border: 0;
    padding: 0;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: black;
    text-decoration: underline;
  }

  &.disabled {
    &:hover, &.active, & {
      background-color: black;
      color: black;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      cursor: not-allowed;
    }

    &.btn-link {
      background-color: transparent;
    }
  }

  &.input {
    position: relative;
    margin-bottom: 15px;

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 2;
      @media (max-width: 767px) {
        height: 53px;
      }
    }
  }
}
