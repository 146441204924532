@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.fc-view-container{
  span{
    padding: 15px 5px;
  }
  td, th {
    height: 28px;
  }
}
