$font-family-free: 'Freestyle Script';

//Colors
$white: #FFFFFF;
$black: #000000;
$dove: #666666;
$verdun: #4F7D00;
$lime: #A6FD10;
$grennBtn: #00D300;
$lime-hover: scale-color($lime, $lightness: -20%);
$error: #ff1d00;
$alabaster: #f5f5f5;

//Containers
$sm-container-width: 350px;
//Containers
$sm-container-width: 350px;
