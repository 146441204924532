@import "../../scss/variables";

.loader-component-react {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($white, 1);
  z-index: 1309;
  margin: 0 !important;

  .content {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: -5%;

    &::after {
      content: "";
      width: 150px;
      height: 20px;
      border-radius: 100%;
      background-color: rgba($black, 0.3);
      position: absolute;
      left: 0;
      right: 0;
      top: 160px;
      bottom: 0;
      margin: auto;
      -webkit-animation: 2s ease-in-out infinite scale-shadow;
      animation: 2s ease-in-out infinite scale-shadow -1.25s;
    }
  }

  @keyframes scale-shadow {
    0%,
    100% {
      transform: scale(0.7);
      -webkit-transform: scale(0.7)
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1)
    }
  }

  .text {
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 260px;
    bottom: 0;
    right: 0;
    margin: auto;
    color: purple;
    text-align: center;
    height: 42px;
    font-size: 20px;
    font-weight: 500;

    h5 {
      width: 180px;
      margin: 0 auto;
    }

  }

  //Spinner #1
  .mesh-loader {
    overflow: hidden;
    height: 150px;
    width: 200px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .mesh-loader .circle {
    width: 25px;
    height: 25px;
    position: absolute;
    background: #390066;
    border-radius: 50%;
    margin: -12.5px;
    -webkit-animation: mesh 3s ease-in-out infinite;
    animation: mesh 3s ease-in-out infinite -1.5s;
  }

  .mesh-loader > div .circle:last-child {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .mesh-loader > div {
    position: absolute;
    top: 60%;
    left: 50%;
  }

  .mesh-loader > div:last-child {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  @-webkit-keyframes mesh {
    0% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    50% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    50.00001% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes mesh {
    0% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    50% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    50.00001% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  //Spinner #3
  .spinner {
    width: 80px;
    height: 80px;
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 4%;
    bottom: 0;
    right: 0;
    margin: auto;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #390066;
    opacity: .7;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: 3s ease-in-out infinite sk-bounce;
    animation: 3s ease-in-out infinite sk-bounce
  }

  .double-bounce2 {
    -webkit-animation-delay: -2s;
    animation-delay: -2s
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0)
    }
    50% {
      -webkit-transform: scale(1)
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0)
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1)
    }
  }
}
