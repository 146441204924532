@font-face {
    font-family: 'Bahnschrift';
    src: url('Bahnschrift.eot');
    src: url('Bahnschrift.eot?#iefix') format('embedded-opentype'),
        url('Bahnschrift.woff2') format('woff2'),
        url('Bahnschrift.woff') format('woff'),
        url('Bahnschrift.ttf') format('truetype'),
        url('Bahnschrift.svg#Bahnschrift') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Freestyle Script';
    src: url('FreestyleScript-Regular.eot');
    src: url('FreestyleScript-Regular.eot?#iefix') format('embedded-opentype'),
        url('FreestyleScript-Regular.woff2') format('woff2'),
        url('FreestyleScript-Regular.woff') format('woff'),
        url('FreestyleScript-Regular.ttf') format('truetype'),
        url('FreestyleScript-Regular.svg#FreestyleScript-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

