@import "../../common/scss/variables";

.dashboard-page {

}

.table-reporte{
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
}
