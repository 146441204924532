@import "../../common/scss/variables";


.not-found-component {
  background-color: $white;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  left: 0;
  top: 0;
  margin: 0 !important;

  .img{
    margin-top: 0;
    svg{
      width: 250px;
      height: 250px;
    }

  }

  h1 {
    font-size: 210px;
    font-weight: 900;
    line-height: 210px;
    margin: 0;
    text-shadow: 4px 5px 1px black;
  }

  h3 {
    line-height: 30px;
    font-size: 21px;
    color: $black;
    margin: 25px 0;
  }

  p {
    color: black;
    margin-bottom: 30px;
    font-size: 16px;
  }

  .content {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 500px;
    height: 600px;
  }
}
