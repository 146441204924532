@import "../../common/scss/variables";

.login-page{
  background-image: url("../../common/resources/images/login/back-login.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  .wrap{
    max-width: 1500px !important;
    margin: 0 auto !important;
  }
  h2{
    font-family: $font-family-free;
    font-size: 70px;
    color: $dove;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }
  .MuiFormControl-root{
    width: 100%;
    margin-bottom: 20px;
    input{
      border-radius: 35px;
      background-color: $white;
      padding-left: 25px;
    }
    .MuiOutlinedInput-notchedOutline{
      border-radius: 35px;
    }
  }
  .img{
    margin-top: -200px;
    position: absolute;
    left: 0;
    top: 300px;
    width: 840px;
    @media (max-width: 768px) {
      display: none;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
  .logo{
    @media (max-width: 768px) {
      margin-top: -50px;
    }
    img{
      max-width: 370px;
      width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
      position: relative;
      left: 8vw;
      @media (max-width: 768px) {
        left: 0;
      }
    }
  }
  label{
    width: 150px;
    @media (max-width: 768px) {
      width: 170px;
    }
    span{
      font-size: 18px;
      &::before{
        content: "";
        width: 20px;
        height: 23px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        margin-left: 15px;
      }
      &.user:before{
        background-image: url("../../common/resources/images/user.png");
      }
      &.pass:before{
        background-image: url("../../common/resources/images/pass.png");
      }
    }
    &.Mui-focused{
      span{
        color: $black;
        @media (max-width: 768px) {
          border: 2px solid $lime-hover;
          background-color: $white;
          border-radius: 12px;
          padding: 0 15px 5px 0;
        }
      }
    }
  }
  .form{
    width: 95%;
    margin: 0 auto;
    background-color: #f3f3f3;
    border-radius: 25px;
    padding: 35px 25px 35px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    margin-top: 30vh;
    max-width: 500px;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 25px;
      margin-top: -25vw;
    }
    .btn{
      margin-top: 25px;
      background-color: $lime;
      color: $black;
      border-radius: 25px;
      font-weight: bold;
      font-size: 18px;
      &.active {
        background-color: scale-color($lime, $lightness: -20%);
      }
      @media (min-width: 769px) {
        &:hover {
          background-color: scale-color($lime, $lightness: -20%);
        }
      }
    }
  }
}
